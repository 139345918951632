import { FC, memo } from 'react'
import { DropTargetMonitor, useDrop } from 'react-dnd'

export interface TargetBoxProps {
  onDrop: (item: any) => void
  types: string[]
  children: JSX.Element
}

export const TargetBox: FC<TargetBoxProps> = memo(function TargetBox({ onDrop, children, types }) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: types,
      drop(_item: any) {
        onDrop(_item)
        return undefined
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop],
  )

  return (
    <div
      ref={drop}
      className={isOver ? 'outline-[5px] outline-red-400 outline-dashed z-9 relative' : ''}
      role="TargetBox"
    >
      {children}
    </div>
  )
})
