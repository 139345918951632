import { API_AUCTION, API_AUCTION_ITEM, API_PRODUCT, API_PRODUCT_ITEM } from 'config'
import Api from 'services/api'

export const getAuctions = () => {
  return Api.get(API_AUCTION)
}

export const createAuction = (data: Record<string, any>) => {
  return Api.uploadFiles(API_AUCTION, data, {}, [])
}

export const updateAuction = (id: number, data: Record<string, any>) => {
  return Api.uploadFiles(API_AUCTION_ITEM, data, { id }, [])
}

export const deleteAuction = (id: number) => {
  return Api.delete(API_AUCTION_ITEM, {}, { id })
}

export const getProducts = (auctionId: number) => {
  return Api.get(API_PRODUCT, {}, { auctionId })
}

export const createProduct = (auctionId: number, data: Record<string, any>) => {
  return Api.post(API_PRODUCT, data, { auctionId })
}

export const updateProduct = (auctionId: number, id: number, data: Record<string, any>) => {
  return Api.put(API_PRODUCT_ITEM, data, { auctionId, id })
}

export const deleteProduct = (auctionId: number, id: number) => {
  return Api.delete(API_PRODUCT_ITEM, {}, { auctionId, id })
}
