import type { CustomInput, InputType, ModalProps } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { createAuction, updateAuction } from 'services'
import { Modal } from 'stories/components'
import { formatDateYMD, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { IAuction } from './types'

const defaultInputs = (): Record<string, InputType> => {
  return {
    title: {
      title: 'Title',
      inputType: 'text',
      error: '',
      required: true,
      span: 'full',
    },
    location: {
      title: 'Location',
      inputType: 'text',
      error: '',
      required: true,
      span: 'full',
    },
    description: {
      title: 'Description',
      inputType: 'textarea',
      error: '',
      required: true,
      span: 'full',
    },
    startDate: {
      title: 'Start Date',
      inputType: 'text',
      type: 'date',
      error: '',
      required: true,
    },
    endDate: {
      title: 'End Date',
      inputType: 'text',
      type: 'date',
      error: '',
    },
    startTime: {
      title: 'Start Time',
      inputType: 'text',
      type: 'time',
      error: '',
    },
    link: {
      title: 'Link',
      inputType: 'text',
      error: '',
      required: true,
      span: 'full',
    },
    footer: {
      title: 'Footer',
      inputType: 'textarea',
      error: '',
      required: true,
      span: 'full',
    },
    image: {
      title: 'Image',
      inputType: 'file',
      multiple: false,
      acceptFileTypes: 'image/*',
      error: '',
      required: true,
    },
    imagePreview: {
      inputType: 'custom',
      title: 'Image Preview',
    },
    type: {
      title: 'Type',
      inputType: 'select',
      options: {
        online: 'Online',
        offline: 'Offline',
      },
      hasDefaultOption: true,
      error: '',
      required: true,
    },
    status: {
      title: 'Status',
      inputType: 'select',
      options: {
        active: 'Active',
        sold: 'Sold',
      },
      hasDefaultOption: true,
      error: '',
      required: true,
    },
  }
}

export const AuctionDialog = (props: ModalProps & { data: IAuction | null }) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  useEffect(() => {
    if (!props.data) return
    const newInputs = cloneDeep(inputs)
    props.data.startDate = formatDateYMD(props.data.startDate)
    props.data.endDate = formatDateYMD(props.data.endDate)
    props.data.startTime = props.data.startTime != null ? props.data.startTime : ''
    Object.keys(newInputs).forEach((key) => (newInputs[key].value = (props.data as any)[key]))
    setInputs(newInputs)
  }, [])

  const onChangeInputs = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onOk = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)

    delete inputData.preview

    try {
      if (!props.data) {
        const newValues = await createAuction(inputData)
        props.onSubmit && props.onSubmit(newValues)
      } else {
        const newValues = await updateAuction(props.data.id, inputData)
        props.onSubmit && props.onSubmit(newValues)
      }
    } catch (e) {}
    setLoading(false)
  }

  const renderImagePreview = () => {
    const image = inputs.image.value
    if (!image) return <div />
    const link = typeof image == 'string' ? props.data?.imageUrl : URL.createObjectURL(image)
    return <img src={link} className="h-40 aspect-video border-gray-200 border-4 object-cover" />
  }

  return (
    <Modal
      isOpen
      title={!props.data ? 'Add New Auction' : `Update Auction #${props.data.id}`}
      titleOkay="Submit"
      onOk={onOk}
      onClose={() => props.onClose()}
      loading={isLoading}
    >
      <div className={`text-gray-600 text-md w-128`}>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-1">
          {Object.keys(inputs).map((key, index) => {
            const input = inputs[key]
            if (key == 'imagePreview') (input as CustomInput).render = renderImagePreview
            return (
              <div key={index} className={`input ${input.span ? 'col-span-full' : 'col-span-1'}`}>
                <RenderInput input={input} Key={key} onChange={onChangeInputs} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
