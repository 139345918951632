import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getContacts } from 'services'
import { svgSearch } from 'stories/assets'
import { Input } from 'stories/components'
import { formatDate } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { renderHeader } from 'utils/table'

import { IContact, InterestedInTypeText } from './types'

export const Contacts = () => {
  useTitle(`Contacts`)

  const [isLoading, setLoading] = useState(false)
  const [values, setValues] = useState<IContact[]>([])
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'createdAt',
    orderDir: -1,
  })

  useEffect(() => {
    setLoading(true)
    getContacts().then((values: IContact[]) => {
      setValues(values)
      setLoading(false)
    })
  }, [])

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
  }

  const onSort = (key: string, sortOrder: number) => {
    if (sortOrder == 0) sortOrder = -1
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="flex flex-wrap items-center gap-2 mb-3">
          <h1 className="text-2xl font-variation-settings-600">
            <span>Contacts</span>
          </h1>
        </div>

        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-3">
          <Input
            type="search"
            title="Search ..."
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>

        <div className="table-container relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="font-variation-settings-600">
                <th scope="col" className="px-2 py-3 w-[40px]">
                  No
                </th>
                {renderHeader({
                  title: 'Name',
                  key: 'name',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'name' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Phone',
                  key: 'phone',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'phone' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Email',
                  key: 'email',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'email' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Type',
                  key: 'type',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'type' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Comment',
                  key: 'comment',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'comment' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Created At',
                  key: 'createdAt',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'createdAt' ? parseInt(filters.orderDir) : 0,
                  className: 'w-[150px]',
                })}
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {values
                .filter((v) => {
                  const { query } = filters
                  if (!query) return true
                  return (
                    (v.name && v.name.toLowerCase().includes(query.toLowerCase())) ||
                    (v.phone && v.phone.toLowerCase().includes(query.toLowerCase())) ||
                    (v.email && v.email.toLowerCase().includes(query.toLowerCase())) ||
                    (v.comment && v.comment.toLowerCase().includes(query.toLowerCase()))
                  )
                })
                .sort((a: any, b: any) => (a[filters.orderBy] > b[filters.orderBy] ? 1 : -1) * filters.orderDir)
                .map((value, index) => {
                  return (
                    <tr key={`${value.createdAt}-${index}`} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                      <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                        {index + 1}
                      </td>

                      <td className="px-2 py-2">{value.name}</td>

                      <td className="px-2 py-2">{value.phone}</td>

                      <td className="px-2 py-2">{value.email}</td>

                      <td className="px-2 py-2">{InterestedInTypeText[value.type]}</td>
                      <td className="px-2 py-2">{value.comment}</td>

                      <td className="px-2 py-2">{formatDate(value.createdAt)}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
