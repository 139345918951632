import { ArrowPathIcon, CheckIcon, ClockIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { Color } from 'stories/types'

import { Checkbox } from '../Checkbox/Checkbox'
import { Tooltip } from '../Tooltip/Tooltip'

interface MultiSelectProps {
  /**
   * What background color to use
   */
  color?: Color
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Is readOnly
   */
  readOnly?: boolean
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Id of Input
   */
  id: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: string[]
  /**
   * Error of Input
   */
  error?: string
  /**
   * Options of select
   */
  options?: Array<string> | Record<string, string> // (Title => key)
  /**
   * Custom class name
   */
  className?: string

  gridClassName?: string
  /**
   * Required
   */
  required?: boolean
  /**
   * Has default option - 'SELECT'
   */
  hasDefaultOption?: boolean
  /**
   * Default option text
   */
  defaultOptionText?: string
  /**
   * Optional history handler
   */
  /**
   * Prefix
   */
  prefix?: string
  /**
   * Show History
   */
  history?: boolean

  additionalElements?: JSX.Element | null
  /**
   * Optional click handler
   */
  onChange?: (value: any) => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const MultiSelectPanel = ({
  color = 'sky',
  disabled = false,
  readOnly = false,
  tooltip = '',
  title = '',
  name = '',
  value = [],
  error = '',
  options = [],
  className = '',
  gridClassName = 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4',
  prefix = '',
  required = false,
  history = false,
  additionalElements = null,
  onChange = () => {},
  showHistory = () => {},
}: MultiSelectProps) => {
  const classNames = [
    'block',
    'rounded-t',
    'px-2.5',
    'pb-[2px]',
    'pt-[27px]',
    'w-full',
    'text-[15px]',
    'text-gray-900',
    'disabled:opacity-100',
    disabled ? 'bg-gray-100' : 'bg-white',
    readOnly ? 'cursor-not-allowed' : '',
    'border',
    'border-gray-300',
    'focus:outline-none',
    'focus:ring-0',
    `focus:border-${color}-600`,
    'peer',
    'text-left',
    'relative',
    prefix.length > 0 && 'pl-7',
    error && 'border-rose-700',
  ]
  if (value === null) value = []

  const isArray = Array.isArray(options)
  const keys = isArray ? options : Object.keys(options)

  const onChangeValue = (key: string, v: boolean) => {
    const newValue = cloneDeep(value)
    if (v && !newValue.includes(key)) newValue.push(key)
    else if (!v && newValue.includes(key)) {
      const index = newValue.indexOf(key)
      newValue.splice(index, 1)
    }
    onChange(newValue)
  }

  const onReset = () => {
    onChange([])
  }

  const onSelectAll = () => {
    onChange(Array.isArray(options) ? options : Object.keys(options))
  }

  return (
    <div className="input-container">
      <div className={`group relative z-10 w-full group ${classNames.join(' ')} ${className}`}>
        <label
          htmlFor={name}
          className="absolute text-[12px] text-gray-700 top-1.5 border-b z-10 origin-[0] left-2.5 flex gap-2 items-center"
        >
          {title}
          {required && '*'}
          {tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
          {additionalElements}
          {history && (
            <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
          <span className="ml-1 hidden group-hover:inline" onClick={() => onReset()}>
            <ArrowPathIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
          </span>
          <span className="ml-1 hidden group-hover:inline" onClick={() => onSelectAll()}>
            <CheckIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
          </span>
        </label>
        <div className={`w-full p-2 grid items-center ${gridClassName}`}>
          {keys.map((key) => (
            <div key={key}>
              <Checkbox
                title={isArray ? key : options[key]}
                id={key}
                color="gray"
                checked={value.includes(key)}
                onChange={(value) => onChangeValue(key, value)}
              />
            </div>
          ))}
        </div>
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1">{error}</p>}
    </div>
  )
}
