export enum InterestedInType {
  Buying = 'buying',
  Selling = 'selling',
}

export const InterestedInTypeText: Record<InterestedInType, string> = {
  [InterestedInType.Buying]: 'Buying',
  [InterestedInType.Selling]: 'Selling',
}

export interface IContact {
  name: string
  phone: string
  email: string
  type: InterestedInType
  comment: string
  createdAt: string
}
