import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

export const Tooltip = ({ message, children = null }: { message: string; children?: JSX.Element | null }) => {
  const [show, setShow] = useState(false)

  return (
    <div
      className="relative flex flex-col items-center"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children || (
        <span className="text-gray-500 cursor-pointer">
          <QuestionMarkCircleIcon className="w-[14px] h-[14px]" />
        </span>
      )}
      {show && (
        <div className="absolute bottom-0 flex flex-col items-center mb-6 flex font-variation-settings-500">
          <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-gray-600 shadow-lg rounded-md">
            <span className="block whitespace-normal max-w-xl" dangerouslySetInnerHTML={{ __html: message }} />
          </span>
          <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div>
        </div>
      )}
    </div>
  )
}
