export const UNEXPECTED_ERROR = 'Sorry, Unexpected Error happen. Please try to connect support!'
export const INVALID_ALL_INPUTS = 'Please input all required information!'

export const SUCCESSFULLY_SUBMIT = 'Successfully submitted'
export const SUCCESSFULLY_CREATED = 'Successfully created'
export const SUCCESSFULLY_UPDATE = 'Successfully updated'

export const INVALID_REQUEST = 'Invalid Url or Request!'
export const NEED_UNDERWRITER = 'You need to select Underwriter!'

export const CONDITION_TEMPLATE_REQUIRED = 'Condition Template is Required!'
