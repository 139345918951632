import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { deleteProduct, getProducts } from 'services'
import { confirm } from 'utils'

import { ProductDialog } from './ProductDialog'
import type { IProduct } from './types'

export const ProductList = ({ auctionId, onClose }: { auctionId: number; onClose: Function }) => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<IProduct[]>([])
  const [currentItem, setCurrentItem] = useState<IProduct | null>()

  useEffect(() => {
    setLoading(true)
    getProducts(auctionId)
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }, [auctionId])

  const onAddSubmit = (item: IProduct) => {
    const newValues = cloneDeep(data)
    const index = newValues.findIndex((v) => v.id == item.id)
    if (index == -1) newValues.push(item)
    else newValues[index] = item

    setData(newValues)
    setCurrentItem(undefined)
  }

  const onDelete = async (item: IProduct) => {
    if (item.id) {
      const result = await confirm('Do you want to remove this data?')
      if (!result) return
    }

    const index = data.findIndex((v) => {
      if (item.id) return v.id === item.id
      else v.createdAt === item.createdAt
    })
    if (index === -1) return

    setLoading(true)
    if (item.id) await deleteProduct(auctionId, item.id)

    const newValues = cloneDeep(data)
    newValues.splice(index, 1)
    setData(newValues)
    setLoading(false)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold">Products</p>
        <div className="flex gap-2">
          <p className="text-sm text-shade-blue cursor-pointer hover:underline" onClick={() => setCurrentItem(null)}>
            + New Product
          </p>
          <p className="text-sm text-red-500 cursor-pointer hover:underline" onClick={() => onClose()}>
            Close
          </p>
        </div>
      </div>

      <table className="w-full text-sm text-left text-gray-700 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="font-variation-settings-600">
            <th scope="col" className="px-2 py-3 w-[40px]">
              No
            </th>
            <th scope="col" className="px-2 py-2">
              Title
            </th>
            <th scope="col" className="px-2 py-2 w-32">
              Quantity
            </th>
            <th scope="col" className="px-2 py-2 w-32">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((v, index) => (
            <tr>
              <td className="px-2 py-2">{index + 1}</td>
              <td className="px-2 py-2">{v.title}</td>
              <td className="px-2 py-2">{v.quantity}</td>
              <td className="px-2 py-2">
                <div className="flex">
                  <button
                    className="text-shade-blue hover-shadow1 cursor-pointer p-1"
                    onClick={() => setCurrentItem(v)}
                  >
                    <PencilSquareIcon className="w-4 h-4" />
                  </button>

                  <button className="text-red-700 hover-shadow1 cursor-pointer p-1" onClick={() => onDelete(v)}>
                    <TrashIcon className="w-4 h-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {currentItem !== undefined && (
        <ProductDialog
          data={currentItem}
          auctionId={auctionId}
          onSubmit={onAddSubmit}
          onClose={() => setCurrentItem(undefined)}
        />
      )}
    </div>
  )
}
