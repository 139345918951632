// import { AdminTools } from 'pages/AdminTool'
import { Auctions } from 'pages/Auctions'
import { Contacts } from 'pages/Contacts'
import { ManageAccounts } from 'pages/ManageAccounts'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

export function MainRoute() {
  const auth = useSelector((state: any) => state.auth)
  if (!auth.isAuthenticated) return null

  return (
    <Routes>
      <Route path="/auctions" element={<Auctions />} />
      <Route path="/contacts" element={<Contacts />} />

      <Route path="/manageAccounts" element={<ManageAccounts />} />
      {/* <Route path="/adminTool" element={<Navigate to="/adminTool/placeholders" replace />} />
      <Route path="/adminTool/:menu" element={<AdminTools />} /> */}
      <Route path="*" element={<Navigate to="/auctions" replace />} />
    </Routes>
  )
}
