export const baseUrl = process.env.REACT_APP_API_URL

export const API_USER_SIGN_IN = '/auth/login'
export const API_USER_FORGET_PASSWORD = '/auth/forgetPassword'
export const API_USER_CHECK_PASSPORT = '/auth/checkPassport/:selector/:token'
export const API_USER_RESET_PASSWORD = '/auth/resetPassword/:selector/:token'
export const API_USER_ACCOUNT_EXECUTIVES = '/auth/getAccountExecutives'
export const API_USER_REGISTER = '/auth/register'

export const API_USER_PROFILE = '/user/profile'

export const API_AUCTION = '/auction'
export const API_AUCTION_ITEM = '/auction/:id'

export const API_PRODUCT = 'auction/:auctionId/product'
export const API_PRODUCT_ITEM = 'auction/:auctionId/product/:id'

export const API_CONTACTS = '/contact'

export const API_ACCOUNT = '/admin/account'
export const API_ACCOUNT_ITEM = '/admin/account/:id'
