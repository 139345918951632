export enum AuctionStatus {
  Active = 'active',
  Sold = 'sold',
}

export enum AuctionType {
  Online = 'online',
  Offline = 'offline',
}

export const AuctionStatusText: Record<AuctionStatus, string> = {
  [AuctionStatus.Active]: 'Active',
  [AuctionStatus.Sold]: 'Sold',
}

export const AuctionTypeText: Record<AuctionType, string> = {
  [AuctionType.Online]: 'Online',
  [AuctionType.Offline]: 'Offline',
}

export interface IAuction {
  id: number
  title: string
  description: string
  location: string
  startDate: string
  endDate: string
  startTime: string
  link: string
  footer: string
  type: AuctionType
  status: AuctionStatus

  image: string
  imageUrl?: string

  createdBy?: string
  createdAt: string
}

export interface IProduct {
  id: number
  auctionId: number
  title: string
  quantity: number
  link: string
  createdAt: string
}
