import { ListBulletIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { deleteAuction, getAuctions } from 'services'
import { svgSearch } from 'stories/assets'
import { Button, Checkbox, Input } from 'stories/components'
import { confirm, formatDate } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { renderHeader } from 'utils/table'

import { AuctionDialog } from './AuctionDialog'
import { ProductList } from './ProductList'
import { type IAuction, AuctionStatusText, AuctionTypeText } from './types'

export const Auctions = () => {
  useTitle(`Auctions`)

  const [isLoading, setLoading] = useState(false)
  const [currentItem, setCurrentItem] = useState<IAuction | null>()
  const [values, setValues] = useState<IAuction[]>([])
  const [activeId, setActiveId] = useState(0)
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    showDescription: false,
    orderBy: 'createdAt',
    orderDir: -1,
  })

  useEffect(() => {
    setLoading(true)
    getAuctions().then((values: IAuction[]) => {
      setValues(values)
      setLoading(false)
    })
  }, [])

  const onAdd = () => {
    setCurrentItem(null)
  }

  const onAddSubmit = (item: IAuction) => {
    const newValues = cloneDeep(values)
    const index = newValues.findIndex((v) => v.id == item.id)
    if (index == -1) newValues.push(item)
    else newValues[index] = item

    setValues(newValues)
    setCurrentItem(undefined)
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
  }

  const onSort = (key: string, sortOrder: number) => {
    if (sortOrder == 0) sortOrder = -1
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
  }

  const onDelete = async (item: IAuction) => {
    if (item.id) {
      const result = await confirm('Do you want to remove this data?')
      if (!result) return
    }

    const index = values.findIndex((v) => {
      if (item.id) return v.id === item.id
      else v.createdAt === item.createdAt
    })
    if (index === -1) return

    setLoading(true)
    if (item.id) await deleteAuction(item.id)

    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
    setLoading(false)
  }

  const onExpand = (item: IAuction) => {
    if (activeId == item.id) setActiveId(0)
    else setActiveId(item.id)
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="flex flex-wrap items-center gap-2 mb-3">
          <h1 className="text-2xl font-variation-settings-600">
            <span>Auctions</span>
          </h1>
          <div className="flex-1" />
          <Button onClick={onAdd}>New Auction</Button>
        </div>

        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-3">
          <Input
            type="search"
            title="Search ..."
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
          <Checkbox
            id="showDescription"
            title="Description"
            value={filters.showDescription}
            onChange={(value) => onChangeFilter('showDescription', value)}
          />
        </div>

        <div className="table-container relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="font-variation-settings-600">
                <th scope="col" className="px-2 py-3 w-[40px]">
                  No
                </th>
                {renderHeader({
                  title: 'Title',
                  key: 'value',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'title' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Type',
                  key: 'type',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'type' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Status',
                  key: 'status',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'status' ? parseInt(filters.orderDir) : 0,
                })}
                {!filters.showDescription ? (
                  <>
                    {renderHeader({
                      title: 'Start Date',
                      key: 'startDate',
                      index: 0,
                      onSort,
                      sortable: true,
                      sortOrder: filters.orderBy == 'startDate' ? parseInt(filters.orderDir) : 0,
                    })}
                    {renderHeader({
                      title: 'End Date',
                      key: 'endDate',
                      index: 0,
                      onSort,
                      sortable: true,
                      sortOrder: filters.orderBy == 'endDate' ? parseInt(filters.orderDir) : 0,
                    })}
                    <th scope="col" className="px-2 py-3 w-[150px]">
                      Created By
                    </th>
                    {renderHeader({
                      title: 'Created At',
                      key: 'createdAt',
                      index: 0,
                      onSort,
                      sortable: true,
                      sortOrder: filters.orderBy == 'createdAt' ? parseInt(filters.orderDir) : 0,
                      className: 'w-[150px]',
                    })}
                    <th scope="col" className="px-2 py-3 w-[100px]">
                      Action
                    </th>
                  </>
                ) : (
                  <>
                    <th scope="col" className="px-2 py-3">
                      Description
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Footer Description
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {values
                .filter((v) => {
                  const { query } = filters
                  if (!query) return true
                  return (
                    (v.title && v.title.toLowerCase().includes(query.toLowerCase())) ||
                    (v.description && v.description.toLowerCase().includes(query.toLowerCase()))
                  )
                })
                .sort((a: any, b: any) => (a[filters.orderBy] > b[filters.orderBy] ? 1 : -1) * filters.orderDir)
                .map((value, index) => {
                  const isActive = activeId == value.id
                  return (
                    <>
                      <tr key={`${value.createdAt}-${index}`} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                        <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                          {index + 1}
                        </td>
                        <td className="hover:underline cursor-pointer px-2 py-2 w-72">
                          <span onClick={() => onExpand(value)}>{value.title}</span>
                        </td>
                        <td className="px-2 py-2 w-12">{AuctionTypeText[value.type]}</td>
                        <td className="px-2 py-2 w-12">{AuctionStatusText[value.status]}</td>

                        {!filters.showDescription ? (
                          <>
                            <td className="px-2 py-2 w-12">{formatDate(value.startDate)}</td>
                            <td className="px-2 py-2 w-12">{formatDate(value.endDate)}</td>
                            <td className="px-2 py-2">{value.createdBy}</td>

                            <td className="px-2 py-2">{formatDate(value.createdAt)}</td>

                            <td className="px-2 py-2">
                              <span className="flex">
                                <button
                                  className="text-shade-blue hover-shadow1 cursor-pointer p-1"
                                  onClick={() => setCurrentItem(value)}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </button>

                                <button
                                  className="text-red-700 hover-shadow1 cursor-pointer p-1"
                                  onClick={() => onDelete(value)}
                                >
                                  <TrashIcon className="w-4 h-4"></TrashIcon>
                                </button>

                                <span
                                  className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                                  onClick={() => onExpand(value)}
                                >
                                  <ListBulletIcon className="w-4 h-4" />
                                </span>
                              </span>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="px-2 py-2">{value.description}</td>
                            <td className="px-2 py-2">{value.footer}</td>
                          </>
                        )}
                      </tr>
                      {isActive ? (
                        <tr>
                          <td />
                          <td colSpan={!filters.showDescription ? 7 : 4}>
                            <div className="p-2">
                              <ProductList auctionId={value.id} onClose={() => setActiveId(0)} />
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  )
                })}
            </tbody>
          </table>
          {currentItem !== undefined && (
            <AuctionDialog data={currentItem} onSubmit={onAddSubmit} onClose={() => setCurrentItem(undefined)} />
          )}
        </div>
      </div>
    </div>
  )
}
